export const PROD_LINK = '4homefitness.com';
export const PROD_LINK_NEW = 'muscle-booster.io';
export const PROD_LINK_CABINET = 'app.muscle-booster.io';

export const PISTOL_EXERCISE_LINK_WEBP =
    '    https://mobile.appscdn.io/!VIDEO/Men%60s%20App/Public/For%20payment%20screens/steffan_box-pistol-right.webp';
export const PISTOL_EXERCISE_LINK_MP4 =
    'https://mobile.appscdn.io/!VIDEO/Men%60s%20App/Public/Full%203D%20Black/Video/Steffan_Box%20Pistol%20%28Right%29.mp4';
export const CHAIR_EXERCISE_VIDEO_LINK =
    'https://web.appscdn.io/web/WebDivisionFiles/Public/MB(w)/monetisation/general_video.mp4';
export const CHAIR_EXERCISE_PREVIEW =
    'https://web.appscdn.io/web/WebDivisionFiles/Public/MB(w)/monetisation/preview_image.png';
