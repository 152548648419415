import ApiClient from 'services/ApiClient';

import { ValidateResponse } from 'types/payments/validate';
import { MerchantDataParams, MerchantDataResponseType } from 'types/payments/payments';
import { ValidateData, IPaymentFailToReteno, IPaymentFailToRetenoResponse } from 'types/payments/paymentApiInterfaces';

class PaymentApi {
    postInit = async <Data = ValidateData, Response = ValidateResponse>(payload: Data): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('payments/post-init', payload);
    };

    paymentFailToReteno = async <Data = IPaymentFailToReteno, Response = IPaymentFailToRetenoResponse>(
        payload: Data
    ): Promise<Partial<Response>> => {
        return await ApiClient.post<Data, Response>('reteno/event', payload);
    };

    getMerchantData = async <Data = MerchantDataParams, Response = { data: MerchantDataResponseType }>(
        payload: Data
    ): Promise<Response> => {
        return await ApiClient.post<Data, Response>('payments/merchant-data', payload);
    };
}

export default PaymentApi;
