import { OrderType, PaymentMethod, PaymentTypeId } from 'interfaces/Payments/payments';
import { AnalyticSystems } from 'interfaces/Analytic/analytic';

export interface Currency {
    sign: string;
    name: string;
}

export interface Product {
    id: string;
    isRegular?: boolean;
    is_preselected: boolean;
    ltv: number;
    name: string;
    payment_type: string;
    period: number;
    price: number;
    price_per_state: number;
    product_state: string;
    start_price: number;
    start_price_per_state: number;
    trial: number;
}

export interface UpsellProduct {
    id: string;
    is_preselected: boolean;
    ltv: number;
    name: string;
    payment_type: string;
    period: number;
    price: number;
    price_per_state: number;
    product_state: string;
    start_price: number;
    start_price_per_state: number;
    trial: number;
    productCode?: string;
    product_code: string;
}

export enum UserGender {
    male = 'male',
}

export interface userSignUpError {
    status?: number;
    redirectLink?: string;
}

export interface IUserSignUp {
    resetForm: () => void;
    setErrors: (error: { email: string }) => void;
    toNextPage: (status: boolean) => void;
    options?: {
        customURL: string;
    };
}

export interface HashedEmailResponse {
    data: string;
}

export interface ISignUpErrorResponse {
    response: {
        data: {
            error: string;
            app_deeplink?: string;
        };
        status: number;
    };
    headers?: {
        token: string;
    };
}

export interface IInitOneClickPayment {
    toNextPage: (status: { isValid: boolean }) => void;
    screenId: string;
    eventName?: string;
}

export interface IValidateOneClickPaymentMeta {
    orderId?: string;
    subscriptionId?: string;
    payload: IInitOneClickPayment;
}

export interface IOneClickPaymentDataForAnalytic {
    value: number;
    order_id?: string;
    screenId: string;
    payment_type: OrderType;
    subscription_id?: string;
    productId: string;
    eventName?: string;
}

export interface IReplaceSubscription {
    system: PaymentTypeId;
    subscriptionData: {
        product_id: string;
        subscription_id: string;
    };
    screenId: string;
    toNextPage: (status: boolean) => void;
    analytics: Array<AnalyticSystems>;
}

export interface IReplaceSubscriptionDataForAnalytic {
    order: {
        amount: number;
        currency: Currency;
        subscription_id: string;
        order_id: string;
    };
    screenId: string;
    paymentMethod: PaymentMethod;
}
