import { CLP } from '../../../../constants/payment/payments';

import { isLocalHost, removeLocalhostFromObject } from '../../../../helpers/utils';
import { getPriceFromCents } from '../../../../helpers/payment/paymentUtils';
import { calculateLTV } from '../../../../helpers/payment/PaymentFlow/utils';
import { deleteSlash } from '../../../../helpers/other/transformationUtils';
import { cleanObject } from '../../../../helpers/other/dataStructuresUtils';

import { CommonValidateData } from 'interfaces/Payments/payments';
import getAnalyticDataForBackend from './getAnalyticDataForBackend';

const prepareDataBeforeValidation = ({
    productId,
    payment_type,
    trial,
    currency,
    userId,
    testaniaName,
    fullURL,
    paymentMethodName,
    amount,
    screenId,
    paymentMethod,
    order,
    ltv,
    period,
    product_code,
    trialPeriod,
    start_price,
    price,
    defaultAmazonPurchaseData,
}: any) => {
    const amountValue: number = currency === CLP.name ? amount : getPriceFromCents(amount);
    const pageUrl: string = deleteSlash(screenId);
    const isSubscription: boolean = payment_type === 'subscription';

    const data = {
        currency: currency.name,
        value: calculateLTV(ltv, period),
        user_id: userId,
        tariff: productId,
        ab_test_name: testaniaName,
        trial,
        payment_type,
        payment_method_name: paymentMethodName,
        payment_method: paymentMethod,
        flow_link: fullURL,
        product_code: product_code,
        subscription_period: period,
        subscription_trial_period: trialPeriod,
        subscription_price: getPriceFromCents(start_price),
        introductorySubscriptionPrice: getPriceFromCents(price),
        defaultAmazonPurchaseData,
    };

    const normalizedData = cleanObject(isLocalHost() ? removeLocalhostFromObject(data) : data);

    const analyticsForBackEnd = getAnalyticDataForBackend(
        normalizedData,
        {
            amountValue,
            pageUrl,
            paymentMethodName,
        },
        order,
        ''
    );

    const commonValidateData: CommonValidateData = {
        order_id: order?.order_id,
        subscription_id: order?.subscription_id,
        payment_method: paymentMethod,
        payment_type,
        product_code: normalizedData.product_code,
        flow_link: normalizedData.flow_link,
        ab_test_name: testaniaName,
        payment_screen: pageUrl,
        analytic_data: analyticsForBackEnd,
    };

    return {
        ...commonValidateData,
        ...(isSubscription
            ? {
                  subscription_period: normalizedData?.subscription_period,
                  subscription_trial_period: normalizedData?.subscription_trial_period,
              }
            : {}),
    };
};

export default prepareDataBeforeValidation;
